<template>
	<!-- for external links -->
	<a
		v-if="externalLink(to, target, getDomainRegion())"
		:id="id"
		:href="externalLink(to, target, getDomainRegion())"
		:name="name"
		:class="linkClasses"
		target="_blank"
		:rel="externalRel(to)"
		:aria-label="ariaLabel"
		v-bind="$attrs"
		v-on="$listeners"
		@click="startInappBrowser($event, externalLink(to, target, getDomainRegion()))"
	>
		<slot />
	</a>
	<!-- for non-app links -->
	<a
		v-else-if="to && !isAppLink(toLink(to), linkType)"
		:id="id"
		v-bind="$attrs"
		:href="to"
		:name="name"
		:class="linkClasses"
		:aria-label="ariaLabel"
		v-on="$listeners"
	>
		<slot />
	</a>
	<!-- internal links -->
	<nuxt-link
		v-else-if="to"
		:id="id"
		v-bind="$attrs"
		:to="toLink(to)"
		:name="name"
		:target="target"
		:class="linkClasses"
		:aria-label="ariaLabel"
		v-on="$listeners"
	>
		<slot />
	</nuxt-link>
	<span
		v-else
		:id="id"
		v-bind="$attrs"
		:to="to"
		:name="name"
		:target="target"
		:class="linkClasses"
		data-no-to="true"
		v-on="$listeners"
	>
		<slot />
	</span>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { IAPILoginUser } from '@hokify/common';
import { piniaAvailable } from '../helpers/piniaStoreAvailable';
import { externalLinkHelper } from '../helpers/externalLink';
import { nativeLinkOpener } from '../helpers/nativeLinkOpener';

export default defineComponent({
	name: 'HokLinkExperimental',
	data() {
		return { piniaAvailable };
	},
	computed: {
		topLevelDomain(): string | undefined {
			return piniaAvailable(this.$nuxt, '$userRootStore')
				? this.$nuxt.context.$userRootStore?.topLevelDomain
				: this?.$store?.state?.topLevelDomain;
		},
		user(): IAPILoginUser | undefined {
			return piniaAvailable(this.$nuxt, '$userProfileStore')
				? this.$nuxt.context.$userProfileStore?.obj
				: this?.$store?.state?.user?.profile?.obj;
		}
	},
	methods: {
		linkClasses() {
			switch (this.styling) {
				case 'typeform':
					return ['inline'];
				case 'inline-block':
					return ['inline-block'];
				default:
					return ['inline-flex'];
			}
		},
		isAppLink(toLink: string, linkType: string | undefined) {
			if (linkType === 'internal') {
				return true;
			}
			if (linkType === 'external') {
				return false;
			}

			// on server side, we can handle all as nuxt links (app link = true)
			if (process.server) {
				return true;
			}

			// do we have this route? if not, we do like it is an external link
			const matchedRoute = this.$router.resolve(toLink);

			if (!matchedRoute || !matchedRoute.resolved?.matched?.length) {
				return false;
			}

			// prevent /company to match as a jobNr
			if (matchedRoute.resolved?.params?.jobNr === 'company') return false;

			return true;
		},
		toLink(to: string) {
			// support "absolute" urls including the base route
			const baseRoute = this.$router.options?.base;

			return baseRoute?.length && baseRoute.length > 1 && to.startsWith(baseRoute)
				? to.substr(baseRoute.length - 1)
				: to;
		},
		getDomainRegion(): string {
			const validRegions = ['at', 'de', 'ch'];

			// 1st: use provided region
			if (this.domainRegion && validRegions.includes(this.domainRegion)) {
				return this.domainRegion;
			}

			// 2nd: use top level domain from store
			if (this.topLevelDomain && validRegions.includes(this.topLevelDomain)) {
				return this.topLevelDomain;
			}

			// 3rd: use region from user obj
			const userObj = this.user;
			if (userObj) {
				const countryCode =
					userObj.general?.address?.countryCode?.toLowerCase() ||
					userObj.internal?.region?.toLowerCase();

				if (validRegions.includes(countryCode)) {
					return countryCode;
				}
			}

			// 4th: fallback to .com
			return 'com';
		},
		externalLink(to: string, target: string, domainRegion: string) {
			return externalLinkHelper(to, target, domainRegion) || '#';
		},
		startInappBrowser($event: Event, externalLink: string) {
			const isCordova =
				(process.client && window.location.protocol === 'file:') || process.env.cordova;
			// only on cordova
			if (isCordova) {
				nativeLinkOpener(externalLink, '_blank', $event, 'location=yes');
			}
		},
		externalRel(to: string) {
			const isAdjustLink = to?.startsWith('https://67ra.adj.st');
			if (isAdjustLink) {
				return 'noopener noreferrer nofollow';
			}
			return 'noopener noreferrer';
		}
	},
	props: {
		to: { type: String, required: true },
		id: { type: String },
		name: { type: String },
		linkType: { type: String },
		target: { type: String, default: '_self' },
		ariaLabel: { type: String },
		domainRegion: { type: String, default: undefined },
		styling: {
			type: String,
			default: undefined,
			validator: (value: string) => ['inline-block', 'typeform'].includes(value)
		}
	}
});
</script>
